import { observable, action, decorate } from "mobx"

const stateKey = 'cn:fav';

const mockLs = {
  getItem(){},
  setItem(){}
}

const ls = typeof window === 'undefined' ? mockLs : window.localStorage;

class Fav {
  Favorites = {}
  AutoRun = false

  AddFav(key, prop) {
    const cleanKey = key.replace(/\/$/, "")
    this.Favorites[cleanKey] = prop;

    const existingState = ls.getItem(stateKey)

    if (existingState) {
      const favs = JSON.parse(ls.getItem(stateKey) || '{}')

      this.Favorites = Object.assign({}, this.Favorites, favs)
    }

    this.lsSync()
  }

  DeleteFav(key) {
    const cleanKey = key.replace(/\/$/, "")
    delete this.Favorites[cleanKey]

    this.lsSync()
  }

  RestoreFavs() {
    const favs = JSON.parse(ls.getItem(stateKey) || '{}')
    this.Favorites = favs;
  }

  lsSync() {
    try {
      ls.setItem(stateKey, JSON.stringify(this.Favorites))
    } catch (e) {
      if (e.code === "22" || e.code === "1024") {
        alert('Oops! Looks like your shortlist has become a long list. You have exceeded the limit of favorite vendors you can have. Try removing some of your favorites.')
      } else {
        alert('Sorry! Something is wrong. In some cases, Private Browsing Mode does not support storing settings locally. Some settings may not save or some features may not work properly for you.')
      }
    }
  }
}
decorate(Fav, {
  Favorites: observable,
  AutoRun: observable,
  AddFav: action,
  GetFav: action,
  DeleteFav: action,
  RestoreFavs: action
})

const FavStore = new Fav()

export default FavStore

import React from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import Axios from 'axios';

import { encode } from  '../lib'

class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal
    }, () => {
      this.refs.contactModal.style.display = this.state.modal ? 'block' : 'none';
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    const payload = {
      "form-name": "contact",
      name: form.name.value,
      email: form.email.value,
      message: form.message.value,
      subject: form.subject.value
    }

    Axios.post("/",
      encode(payload),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" }},
    )
    .then(() => {
      alert("We have received your message. We will get back to you as soon as possible.\nThank you.")
      form.reset();
      this.refs.contactModal.style.display = 'none'
      this.setState({ modal: false })
    })
    .catch(() => {
      alert("Oops! Something went wrong.\nPlease try again later.")
    });
  };

  form() {
    return <Form
      name="contact"
      method="post"
      action="/thanks"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
    >
      <ModalHeader toggle={this.toggle}>Contact Us</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label sm={3} for="name">Name*</Label>
          <Col sm={9}>
            <input type="hidden" name="form-name" value="contact" />
            <Input type="text" name="name" id="name" required />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="email">Email*</Label>
          <Col sm={9}>
            <Input type="email" name="email" id="email" required />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="subject">Subject*</Label>
          <Col sm={9}>
            <Input type="select" name="subject" id="subject" defaultValue="hi">
              <option value="hi">Saying Hi</option>
              <option value="feedback">Feedback</option>
              <option value="service_registration">Service Registration</option>
              <option value="report">Report A Problem</option>
              <option value="business">Business and Ads</option>
              <option value="other">Other</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} for="message">Message*</Label>
          <Col sm={9}>
            <Input type="textarea" name="message" id="message" required />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="danger">Send</Button>
        {' '}
        <Button outline color="dark" onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Form>
  }

  render() {
    return (
      <div>
        <a href="" onClick={this.toggle}>Contact Us</a>

        <div class="modal" tabIndex="-1" role="dialog" ref="contactModal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              { this.form() }
            </div>
          </div>
        </div>
       { this.state.modal && <div class="modal-backdrop fade show"></div> }
      </div>
    );
  }
}

export default ModalForm;

import { Link, withPrefix } from 'gatsby'
import React from 'react'

import FavoritesLink from './favoritesLink';

const Header = ({ categories, siteTitle }) => {

  const getServicesDropdown = () => {
    const servicesList = categories.map(c => <Link key={`category-slug-${c}`} className="dropdown-item" to={`/services/${c}`}>{c.titleize()}</Link>)
    return <div class="dropdown-menu" id="navbarDropdown">
      { servicesList }
    </div>
  }

  const toggleNav = (e) => {
    e.preventDefault();
    const element = document.getElementById(e.target.getAttribute('rel'))
    element.classList.toggle("show");
    e.target.classList.toggle("on");
    e.target.classList.toggle("off");
  }

  return <>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
      <div class="container">
        <Link to="/" className="navbar-brand" href="#" title={siteTitle}>
          <img src={withPrefix('/images/ceremonynepal-logo.png')} alt={siteTitle} height="48" />
        </Link>
        <button
          onClick={toggleNav}
          class="navbar-toggler off"
          type="button"
          data-toggle="collapse"
          rel="navbarResponsive"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="/">Home
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/about">About</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" onClick={toggleNav} rel="navbarDropdown">Services</a>
              <span class="icon-arrow-down"></span>
              { getServicesDropdown() }
            </li>
            <li class="nav-item">
              <FavoritesLink />
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </>
}

export default Header

import React, { Fragment } from "react"
import { observer, inject } from "mobx-react"

import Provider from '../store/provider'

const FavoritesLink = inject(`store`)(
  observer(({ store }) => {

    if (!store.AutoRun) {
      store.RestoreFavs()
      store.AutoRun = true
    }

    const favorites = Object.keys(store.Favorites).length;

    const favLen = <span class="badge badge-warning">
      { favorites }
    </span>

    return <Fragment>
      <a
        href="/favorites"
        class="nav-link"
      > Favorites { favorites ? favLen : ""  }</a>
    </Fragment>
  })
)

export default function() { return <Provider><FavoritesLink /></Provider> }

import { Link } from 'gatsby'
import React from 'react'

import ContactForm from './contactForm'

import FacebookIcon from '../svgs/facebook.svg';
import InstagramIcon from '../svgs/instagram.svg';
import EmailIcon from '../svgs/email.svg';

const Footer = () => {
  return <footer class="main-block dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="footer-link-list" id="PopularCategories">
            <h6>Popular Searches</h6>
            <ul>
              <li>
                <Link to="/services/boutiques/lalitpur" title="Boutiques in Lalitpur">Boutiques in Lalitpur</Link>
              </li>
              <li>
                <Link to="/services/hair-and-beauty/kathmandu" title="Hair and Beauty in Kathmandu">Beauty Salons in Kathmandu</Link>
              </li>
              <li>
                <Link to="/services/photographers/kathmandu" title="Wedding Photographers in Kathmandu">Wedding Photographers In Kathmandu</Link>
              </li>
              <li>
                <Link to="/services/cakes-and-bakeries/kathmandu" title="Cakes in Kathmandu">Designer Cakes In Kathmandu</Link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="footer-link-list">
            <h6>Home</h6>
            <ul>
              <li>
                <a href="/about" title="About CeremonyNepal">About Us</a>
              </li>
              <li>
                <ContactForm />
              </li>
              <li>
                <a href="/search" title="Search on CeremonyNepal">Site Search</a>
              </li>
              <li>
                <a href="/partner-network" title="CeremonyNepal Partner Network">Partner Program</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <div class="copyright">
            <p>2019 CeremonyNepal</p>
            <div class="footer-link-list-contact" id="contactus">
              <a href="https://www.facebook.com/ceremonynepalofficial/" target="_blank" rel="nofollow noopener noreferrer">
                <FacebookIcon /> ceremonynepalofficial
              </a>
              <a href="https://www.instagram.com/ceremonynepal/" target="_blank" rel="nofollow noopener noreferrer">
                <InstagramIcon /> ceremonynepal
              </a>
              <a href="mailto:ceremonynepal@gmail.com" rel="nofollow noopener noreferrer">
                <EmailIcon /> ceremonynepal@gmail.com
              </a>
            </div>
            <div>
              <small>
              Icons by Gan Khoon Lay, Brand Mania, Husein Aziz, Krisada, Danishicon, Ben Davis, Dama Adhikara, Mahmure Alp, Rose Alice Design,Gregor Cresnar from The Noun Project.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer
